import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { apiLogin } from '../../services/api/accounts/Login';

import {
    Grid,
    TextField,
    Container,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import styles from './Login.module.css';

const SetPasswordComponent = (props) => {
    const token = props.match.params.token;
    const { register, handleSubmit, setError, formState } = useForm();
    const [tokenValid, setTokenValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState('');
    const [accountName, setAccountName] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    useEffect(async () => {
        let account = await apiLogin.getCustom(`/set-password/${token}`);
        if (!account || (account.response && account.response.status === 404)) {
            setTokenValid(false);
            return;
        }

        setAccountName(account.name);
    }, [])

    const onSubmit = async (data) => {
        let result = await apiLogin.putCustom('/set-password', data);
        console.log(result);
        if (result.response && result.response.status === 400) {
            setValidationMessage(result.response.data.error);
        } else {
            window.location.href = "/";
        }
    };

    const showPasswordEye = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
    };

    return (
        <Grid container>
            <Grid item xs={8}>
                <div className={styles.LoginBackground}></div>
            </Grid>
            <Grid item xs={4}>
                <Container component="main" maxWidth="sm" className={`${styles.LoginContainer} height100`}>

                    <Grid container direction="column">
                        <a className={styles.LogoSmall}></a>
                        {
                            !tokenValid
                            ? <h3 className="text-center text-danger">Żądanie nie istnieje lub minął termin ważności.</h3>
                            : <Grid>
                                <form onSubmit={handleSubmit(onSubmit)} className={styles.LoginForm}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <h1>{accountName} <br/> Ustaw hasło dla konta</h1>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label="Hasło" helperText="Hasło powinno składać się z od 8 do 24 znaków, małych i wielkich liter, cyfr oraz znaków specjalnych." fullWidth required type={showPassword ? "text" : "password"} name="password" {...register("password", { required: true })} InputProps={showPasswordEye} /><br />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label="Powtórz hasło" fullWidth required type={showPassword ? "text" : "password"} name="passwordRepeat" {...register("passwordRepeat", { required: true })} InputProps={showPasswordEye} /><br />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <input type="hidden" name="token" {...register("token", { required: true })} value={token} />
                                            <button type="submit" className={styles.LoginButton}>Ustaw hasło</button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="text-center text-danger">{validationMessage}</div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default SetPasswordComponent;