import React, { Component } from 'react';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavbarText,
    NavItem,
    NavLink,
    Nav
} from 'reactstrap';
import {
    Tabs,
    Tab
} from '@mui/material';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import LogoutIcon from '@mui/icons-material/Logout';

const pages = [
    {
        link: '/machines',
        name: 'Maszyny'
    },
    {
        link: '/shops',
        name: 'Sklepy'
    },
    {
        link: '/shop/categories',
        name: 'Kategorie sklepów'
    },
    {
        link: '/users',
        name: 'Użytkownicy'
    }
]

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            currentPage: window.location.pathname
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    render() {
        return (
            <header>
                <Container className="main-navbar">
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                        <NavbarBrand tag={Link} to="/" className="LogoSmall">
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <div>
                            <Nav fill>
                                {pages.map((page, i) => {
                                    return (
                                        <NavItem key={i}>
                                            <NavLink tag={Link} className={`text-dark ${(page.link === this.state.currentPage) ? 'active' : ''}`} to={page.link} onClick={() => this.setState({currentPage: page.link})}>
                                                {page.name}
                                                <div className="active-underline"></div>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })}
                                <NavItem className="logout-nav-item">
                                    <LogoutIcon sx={{fontSize: 30, cursor: 'pointer'}} onClick={this.logout}/>
                                </NavItem>
                            </Nav>
                            </div>
                        </Collapse>
                    </Navbar>
                </Container>
            </header>
        );
    }
}
