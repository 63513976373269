import ApiCore from '../utilities/ApiCore';

export const apiUsers = new ApiCore({
    get: false,
    getSingle: true,
    getCustom: true,
    getList: true,
    search: false,
    post: true,
    postCustom: true,
    put: true,
    putCustom: true,
    remove: true,
    removeMany: true,
    changeStatus: true,
    url: 'account',
    plural: 'account',
    single: 'account'
});