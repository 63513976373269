import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { apiMachine } from '../../services/api/machines/Machine'

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    MenuItem,
    DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';
import { Form } from 'reactstrap';

const CreateMachineDialogComponent = (props) => {
    const {
        onCreated,
        editMode,
        id,
        style
    } = props;

    const { register, handleSubmit, reset } = useForm();

    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState(1);
    const [formRef, setFormRef] = React.useState('');

    const handleClickOpen = async () => {
        setOpen(true);

        if (editMode) {
            let data = await apiMachine.getSingle(id);

            if (data.machine) {
                reset({
                    name: data.machine.name,
                    localization: data.machine.localization,
                    status: data.machine.status,
                    serviceCode: data.machine.serviceCode
                });
            }
        }
    }

    const handleClose = () => {
        reset();
        setOpen(false);
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    }

    const submitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        if (!!data.password && data.password !== data.repeatPassword) {
            alert('Podane hasła nie są identyczne');
            return;
        }

        let result = editMode ? await apiMachine.put(id, data)
                              : await apiMachine.post(data);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    return (
        <div className="d-inline" style={style}>
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nową</button>
            }
            <Dialog open={open} onClose={handleClose}>
                {
                    editMode
                    ? <DialogTitle>Edytuj maszynę</DialogTitle>
                    : <DialogTitle>Dodaj maszynę</DialogTitle>
                }
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                    required
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Nazwa"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("name", { required: true })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                    required
                                    margin="dense"
                                    id="localization"
                                    label="Lokalizacja"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("localization", { required: true })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                        required
                                        select
                                        margin="dense"
                                        id="status"
                                        label="Status"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={status}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("status", { required: true })}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem value={0} className="menu-item-select">Nieaktywny</MenuItem>
                                        <MenuItem value={1} className="menu-item-select">Aktywny</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                    required
                                    margin="dense"
                                    id="service-code"
                                    label="Kod serwisowy"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ maxLength: 5 }}
                                    InputLabelProps={{ shrink: true }}
                                    {...register("serviceCode", { required: true })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                    required
                                    type="password"
                                    margin="dense"
                                    label="Hasło"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("password", { required: !editMode })}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className="modified-form">
                                <TextField
                                    required
                                    type="password"
                                    margin="dense"
                                    label="Powtórz hasło"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("repeatPassword", { required: !editMode })}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateMachineDialogComponent.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    id: PropTypes.number
};

export default CreateMachineDialogComponent;