import React from 'react';
import PropTypes from 'prop-types';

import { set, useForm } from 'react-hook-form';

import { apiShop } from '../../services/api/shop/Shop';

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    MenuItem,
    Tabs,
    Tab
} from '@mui/material';
import TabPanel from '../shared/tabs/TabPanel';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CreateShopDialog = (props) => {
    const {
        onCreated,
        editMode,
        id,
        categories,
        machines,
        style
    } = props;

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState('');
    const [selectedMachines, setSelectedMachines] = React.useState([]);
    const [status, setStatus] = React.useState(1);
    const [formRef, setFormRef] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [isFileValid, setIsFileValid] = React.useState(true);
    const [existedImage, setExistedImage] = React.useState('');

    const handleClickOpen = async () => {
        setOpen(true);

        if (editMode) {
            let data = await apiShop.getSingle(id);

            if (data.shop) {
                setCategory(Number(data.shop.categoryId));
                setStatus(data.shop.status);
                setSelectedMachines(data.shop.machines);
                setExistedImage(data.shop.logoPath)

                reset({
                    categoryId: data.shop.categoryId,
                    name: data.shop.name,
                    street: data.shop.street,
                    buildingNumber: data.shop.buildingNumber,
                    premisesNumber: data.shop.premisesNumber,
                    postalCode: data.shop.postalCode,
                    city: data.shop.city,
                    phoneNumber: data.shop.phoneNumber,
                    email: data.shop.email,
                    description: data.shop.description,
                    status: data.shop.status,
                    companyName: data.shop.companyName,
                    nip: data.shop.nip,
                    regon: data.shop.regon,
                    companyStreet: data.shop.companyStreet,
                    companyBuildingNumber: data.shop.companyBuildingNumber,
                    companyPremisesNumber: data.shop.companyPremisesNumber,
                    companyPostalCode: data.shop.companyPostalCode,
                    companyCity: data.shop.companyCity,
                    companyPhoneNumber: data.shop.companyPhoneNumber,
                    companyEmail: data.shop.companyEmail
                });
            }
        }
    }

    const handleClose = () => {
        setCategory('');
        setSelectedMachines([]);
        setStatus('');
        setSelectedFile(null);
        setSelectedTab(0);
        setIsFileValid(true);
        reset();
        setOpen(false);
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    }

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    }

    const handleMachinesChange = (event) => {
        setSelectedMachines(event.target.value);
    }

    const submitForm = () => {
        validateFile();
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        if (!validateFile())
            return;

        const formData = new FormData();
        formData.append('categoryId', category);
        formData.append('name', data.name);
        formData.append('street', data.street);
        formData.append('buildingNumber', data.buildingNumber);
        formData.append('premisesNumber', data.premisesNumber);
        formData.append('postalCode', data.postalCode);
        formData.append('city', data.city);
        formData.append('phoneNumber', data.phoneNumber);
        formData.append('email', data.email);
        formData.append('description', data.description);
        formData.append('status', Number(status));
        formData.append('logo', selectedFile);
        formData.append('machines', selectedMachines);

        formData.append('companyName', data.companyName ?? '');
        formData.append('nip', data.nip ?? '');
        formData.append('regon', data.regon ?? '');
        formData.append('companyStreet', data.companyStreet ?? '');
        formData.append('companyBuildingNumber', data.companyBuildingNumber ?? '');
        formData.append('companyPremisesNumber', data.companyPremisesNumber ?? '');
        formData.append('companyPostalCode', data.companyPostalCode ?? '');
        formData.append('companyCity', data.companyCity ?? '');
        formData.append('companyPhoneNumber', data.companyPhoneNumber ?? '');
        formData.append('companyEmail', data.companyEmail ?? '');

        let result = editMode ? await apiShop.put(id, formData)
                              : await apiShop.post(formData);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const validateFile = () => {
        if (!editMode && !selectedFile) {
            setIsFileValid(false);
            return false;
        }

        setIsFileValid(true);
        return true;
    }

    return (
        <div className="d-inline" style={style}>
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nowy</button>
            }
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Dodaj sklep</DialogTitle>
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                        <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} aria-label="basic tabs example" className="tabs">
                            <Tab label="Dane sklepu" className="tab-button" {...a11yProps(0)} />
                            <Tab label="Dane firmy" className="tab-button" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={selectedTab} index={0}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.name?.message}</span>}
                                        className={(errors?.name) ? 'with-error' : ''}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Nazwa"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors && !status ? 'To pole jest wymagane' : ''}</span>}
                                        className={(errors && !status) ? 'with-error' : ''}
                                        select
                                        margin="dense"
                                        id="status"
                                        label="Status"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={status}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("status", { required: true })}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem className="menu-item-select" value={1}>Aktywny</MenuItem>
                                        <MenuItem className="menu-item-select" value={0}>Nieaktywny</MenuItem>
                                        <MenuItem className="menu-item-select" value={2}>Zablokowany</MenuItem>
                                    </TextField>
                                </Grid>
                                {(machines) &&
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        select
                                        margin="dense"
                                        id="machines"
                                        label="Maszyny, do których jest podpięty sklep"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedMachines}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("machines")}
                                        onChange={handleMachinesChange}
                                        SelectProps={{
                                            multiple: true
                                        }}
                                    >
                                        {machines.map((machine, index) => <MenuItem className="menu-item-select" key={index} value={machine.id}>{machine.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                }
                                {(categories && categories.length > 0) &&
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors && !category ? 'To pole jest wymagane' : ''}</span>}
                                        className={(errors && !category) ? 'with-error' : ''}
                                        select
                                        margin="dense"
                                        id="categoryId"
                                        label="Kategoria"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={category}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("categoryId", { required: true })}
                                        onChange={handleCategoryChange}
                                    >
                                        {categories.map((category, index) => <MenuItem className="menu-item-select" key={index} value={category.id}>{category.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                }
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.street?.message}</span>}
                                        className={(errors?.street) ? 'with-error' : ''}
                                        margin="dense"
                                        id="street"
                                        label="Ulica"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("street", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.buildingNumber?.message}</span>}
                                        className={(errors?.buildingNumber) ? 'with-error' : ''}
                                        margin="dense"
                                        id="buildingNumber"
                                        label="Numer budynku"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("buildingNumber", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="premisesNumber"
                                        label="Numer lokalu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("premisesNumber", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.postalCode?.type === 'required' ? 'To pole jest wymagane' : ''}</span>}
                                        className={(errors?.postalCode) ? 'with-error' : ''}
                                        margin="dense"
                                        id="postalCode"
                                        label="Kod pocztowy"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("postalCode", { required: true })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.city?.message}</span>}
                                        className={(errors?.city) ? 'with-error' : ''}
                                        margin="dense"
                                        id="city"
                                        label="Miasto"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("city", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.phoneNumber?.message}</span>}
                                        className={(errors?.phoneNumber) ? 'with-error' : ''}
                                        margin="dense"
                                        id="phoneNumber"
                                        label="Numer telefonu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("phoneNumber", {
                                            required: 'To pole jest wymagane',
                                            pattern: {
                                                value: /^\+[1-9]{1}[0-9]{9,14}$/,
                                                message: 'Proszę wprowadzić prawidłowy numer telefonu',
                                            }
                                        })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.email?.message}</span>}
                                        className={(errors?.email) ? 'with-error' : ''}
                                        margin="dense"
                                        id="email"
                                        label="Email"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("email", {
                                            required: 'To pole jest wymagane',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Proszę wprowadzić prawidłowy adres email',
                                            }
                                        })}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.description?.message}</span>}
                                        className={(errors?.description) ? 'with-error' : ''}
                                        multiline
                                        rows="3"
                                        margin="dense"
                                        id="description"
                                        label="Opis"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("description", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={12} className={`file-upload-input ${(!isFileValid) ? 'invalid' : ''}`}>
                                    <input type="file" className="form-control file-with-background" multiple="" onChange={handleFileChange} style={(editMode) ? {
                                        backgroundImage: `url(${existedImage})`
                                    } : null}/>
                                    <small>Preferowana wielkość zdjęcia to 120px szerokości oraz 120px wysokości</small>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <Grid container spacing={2}>
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="companyName"
                                        label="Nazwa firmy"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyName", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="nip"
                                        label="Nip"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("nip", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="regon"
                                        label="Regon"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("regon", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyStreet"
                                        label="Ulica"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyStreet", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyBuildingNumber"
                                        label="Numer budynku"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyBuildingNumber", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={3} xs={6} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyPremisesNumber"
                                        label="Numer lokalu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyPremisesNumber", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyPostalCode"
                                        label="Kod pocztowy"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyPostalCode", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyCity"
                                        label="Miasto"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyCity", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyPhoneNumber"
                                        label="Numer telefonu"
                                        type="phone"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyPhoneNumber", { required: false })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        margin="dense"
                                        id="companyEmail"
                                        label="Email"
                                        type="email"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("companyEmail", { required: false })}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateShopDialog.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    id: PropTypes.number,
    categories: PropTypes.array
};

export default CreateShopDialog;