import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { apiUsers } from '../../services/api/accounts/Users';

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';


const CreateUserDialog = (props) => {
    const {
        onCreated,
        editMode,
        id,
        style
    } = props;

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState(true);
    const [formRef, setFormRef] = React.useState('');

    const handleClickOpen = async () => {
        setOpen(true);

        if (editMode) {
            let data = await apiUsers.getSingle(id);

            if (data) {
                setStatus(data.isActive);

                reset({
                    name: data.name,
                    surname: data.surname,
                    email: data.email,
                });
            }
        }
    }

    const handleClose = () => {
        setStatus(true);
        reset();
        setOpen(false);
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    }

    const submitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('surname', data.surname);
        formData.append('email', data.email);
        formData.append('isActive', status);

        let result = editMode ? await apiUsers.put(id, formData)
                              : await apiUsers.post(formData);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    return (
        <div className="d-inline" style={style}>
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nowy</button>
            }
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Dodaj użytkownika</DialogTitle>
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.name?.message}</span>}
                                        className={(errors?.name) ? 'with-error' : ''}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Imię"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.surname?.message}</span>}
                                        className={(errors?.surname) ? 'with-error' : ''}
                                        margin="dense"
                                        id="surname"
                                        label="Nazwisko"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("surname", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.email?.message}</span>}
                                        className={(errors?.email) ? 'with-error' : ''}
                                        margin="dense"
                                        id="email"
                                        label="E-mail"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("email", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        select
                                        margin="dense"
                                        id="status"
                                        label="Status"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={status}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("status", { required: true })}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem className="menu-item-select" value={true}>Aktywny</MenuItem>
                                        <MenuItem className="menu-item-select" value={false}>Nieaktywny</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateUserDialog.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    id: PropTypes.number,
    categories: PropTypes.array
};

export default CreateUserDialog;