import React from 'react';

import {
    Box,
    Button,
    Checkbox,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Menu,
    MenuItem,
    InputBase,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core";
import { styled, alpha } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TableHead from '../shared/table/TableHeadComponent';

import CreateShopDialog from './CreateShopDialogComponent';

import { apiShop } from '../../services/api/shop/Shop';
import tableStyles from '../shared/table/Table.module.css';
import { confirm } from "react-confirm-box";
import { confirmOptions } from '../../services/common/Options';
import AlertDialog from '../shared/dialogs/AlertDialog';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'ID',
        width: '5%'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Nazwa sklepu',
    },
    {
        id: 'createdAt',
        numeric: false,
        disablePadding: true,
        label: 'Data dołączenia',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: true,
        label: 'Kategoria',
    },
    {
        id: 'street',
        numeric: false,
        disablePadding: true,
        label: 'Ulica',
    },
    {
        id: 'buildingNumber',
        numeric: false,
        disablePadding: true,
        label: 'Numer bud.',
    },
    {
        id: 'premisesNumber',
        numeric: false,
        disablePadding: true,
        label: 'Numer lok.',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: true,
        label: 'Miasto',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status',
    }
];

function ChangeStatusMenu(props) {
    const {
        selected,
        onUpdated
    } = props;

    const [alertOpen, setAlertOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeStatus = async (status) => {
        handleClose();

        if (!selected.length) {
            setAlertOpen(true);
            return;
        }

        await apiShop.changeStatus(selected, status);
        onUpdated();
    }

    return (
      <div>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={tableStyles.Button}
        >
          Wybierz
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={async () => await handleChangeStatus(0)}>Nieaktywny</MenuItem>
          <MenuItem onClick={async () => await handleChangeStatus(1)}>Aktywny</MenuItem>
        </Menu>
        <AlertDialog open={alertOpen} handleClose={() => setAlertOpen(false)} content="Nie wybrano żadnego elementu listy." />
      </div>
    );
}

const Search = styled('div')(({ theme }) => ({
position: 'relative',
borderRadius: theme.shape.borderRadius,
backgroundColor: alpha(theme.palette.common.white, 0.15),
'&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
},
flex: '1 1 auto'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
}));

class ShopsComponent extends React.Component {
    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: 'Id',
            search: '',
            selected: [],
            page: 0,
            rowsPerPage: 5,
            rows: [],
            size: 0,
            categories: [],
            machines: []
        };

        this.timer = null;

        this.loadData();
    }

    loadData = () => {
        clearTimeout(this.timer);

        let query = {
            orderBy: this.state.orderBy,
            orderDestination: this.state.order.toUpperCase(),
            start: this.state.page * this.state.rowsPerPage,
            size: this.state.rowsPerPage,
            search: this.state.search
        };

        this.timer = setTimeout(async () => {
            let shops = await apiShop.getList(query);
            this.setState({
                rows: shops.items,
                size: shops.length,
                categories: shops.categories ?? [],
                machines: shops.machines ?? []
            });
        }, 500);
    };

    clearFilters = () => {
        this.setState({
            search: ''
        }, () => {
            this.loadData();
        });
    }

    handleCreated = () => this.loadData();

    handleDelete = async (id) => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrany sklep? Usunięcie rekordów spowoduje usuniecie wszystkich powiązań.", confirmOptions);

        if (result) {
            await apiShop.remove(id);
            this.loadData();
        }
    }

    handleDeleteSelected = async () => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrane sklepy? Usunięcie rekordów spowoduje usuniecie wszystkich powiązań.", confirmOptions);

        if (result) {
            await apiShop.removeMany(this.state.selected);
            this.loadData();
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.rows.map((n) => n.id);
            this.state.selected = newSelecteds;
            this.setState({ selected: newSelecteds });
            return;
        }

        this.setState({ selected: [] });
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';

        this.state.order = property == this.state.orderBy && this.state.order != 'desc' ? 'desc' : 'asc';
        this.state.orderBy = property;

        this.loadData();
    }

    handleChangePage = (event, newPage) => {
        this.state.page = newPage;

        this.setState({ page: newPage });

        this.loadData();
    }

    handleChangeRowsPerPage = (event) => {
        this.state.rowsPerPage = parseInt(event.target.value, 10);
        this.state.page = 0;

        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });

        this.loadData();
    }

    handleCheckboxChange = (id, isChecked) => {
        if (isChecked)  {
            this.state.selected.push(id);
            this.setState({
                selected: this.state.selected
            });
        } else {
            this.state.selected = this.state.selected.filter((item) => item !== id);
            this.setState({
                selected: this.state.selected
            });
        }
    }

    labelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} z ${count !== -1 ? count : `więcej niż ${to}`}`;
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    render() {
        return (
            <Grid>
                <Grid container spacing={1} className={tableStyles.TableMenu}>
                    <Grid item md={12} className="d-flex align-items-baseline">
                        <h1 style={{flex: '0 1 120px'}}>Lista sklepów</h1>
                        <CreateShopDialog onCreated={this.handleCreated} editMode={false} categories={this.state.categories} machines={this.state.machines} style={{flex: '0 1 125px'}}/>
                        <div style={{flex: '0 1 200px'}}>
                            <span className={tableStyles.Label}>Akcje grupowe</span>
                            <Button className={tableStyles.Button} startIcon={<DeleteOutlineIcon />} onClick={this.handleDeleteSelected}>
                                Usuń
                            </Button>
                        </div>
                        <div className="d-flex" style={{flex: '0 1 180px'}}>
                            <span className={tableStyles.Label}>Ustaw status</span>
                            <ChangeStatusMenu selected={this.state.selected} onUpdated={this.loadData} />
                        </div>
                        <Search className={tableStyles.Search}>
                            <SearchIconWrapper>
                            <SearchIcon className={tableStyles.Icon}/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Szukaj..."
                                inputProps={{ 'aria-label': 'search' }}
                                value={this.state.search}
                                onChange={(event) => {
                                    this.setState({
                                        search: event.target.value,
                                        page: 0,
                                    }, () => {
                                        if (this.state.search.length >= 2 || this.state.search.length == 0)
                                            this.loadData();
                                    });
                                }}
                            />
                        </Search>
                    </Grid>
                    <Grid item md={12} className="d-flex justify-content-end">
                        <Button className={tableStyles.Button} startIcon={<CloseIcon />} onClick={this.clearFilters}>
                            Wyczyść filtry
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table className={tableStyles.Table}>
                                <TableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    numSelected={this.state.selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={this.state.rows.length}
                                    canSelectAll
                                />
                                <TableBody>
                                    {this.state.rows.map((row, index) => {
                                        const isItemSelected = this.isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow key={row.id} className={tableStyles.Row}>
                                                <TableCell padding="checkbox" className={tableStyles.CheckboxCell}>
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} onChange={(event) => {
                                                        this.handleCheckboxChange(row.id, event.target.checked);
                                                    }}/>
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={`${tableStyles.ValueCell} ${tableStyles.ValueCellFirst}`}>
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.createdAt}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.category}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.street}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.buildingNumber}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.premisesNumber}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.city}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="right" padding="none" className={tableStyles.CrudCell}>
                                                    <CreateShopDialog onCreated={this.handleCreated} editMode={true} id={row.id} categories={this.state.categories} machines={this.state.machines} />
                                                    <DeleteOutlineIcon role="button" className={tableStyles.Button} onClick={async () => this.handleDelete(row.id)}/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.size}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            labelDisplayedRows={this.labelDisplayedRows}
                            labelRowsPerPage={"Wierszy na stronę:"}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    };
}

export default ShopsComponent;