import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import Login from './components/login/LoginComponent';
import Machines from './components/machines/MachinesComponent';
import Shops from './components/shops/ShopsComponent';
import ShopCategories from './components/shops/ShopCategoriesComponent';
import Users from './components/users/UsersComponent';
import SetPassword from './components/login/SetPasswordComponent';

import './fonts/fonts.css';
import './custom.css';

const isAuthenticated = localStorage.getItem('token') != null && localStorage.getItem('token') != ''
    && localStorage.getItem('token') != 'undefined';

const authRoutes = [
    '/',
    '/machines',
    '/shops',
    '/shop/categories',
    '/users',
]

const App = () => {
    const location = useLocation();
    const history = useHistory();
    const buildLayout = authRoutes.indexOf(location.pathname) >= 0;

    useEffect(() => {
        if (!isAuthenticated && authRoutes.indexOf(location.pathname) >= 0) {
            history.push('/login');
        }
    }, [])

    return (
        <>
            {buildLayout &&
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route path='/machines' component={Machines} />
                    <Route path='/shops' component={Shops} />
                    <Route path='/shop/categories' component={ShopCategories} />
                    <Route path='/users' component={Users} />
                </Layout>
            }
            <Route exact path='/login' component={Login} />
            <Route exact path='/set-password/:token' component={SetPassword} />
        </>
    );
};

export default App;
