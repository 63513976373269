import ApiCore from '../utilities/ApiCore';

export const apiShopCategory = new ApiCore({
    get: false,
    getSingle: true,
    getList: true,
    search: false,
    post: true,
    put: true,
    remove: true,
    removeMany: true,
    url: 'shop/category',
    plural: 'shop/category',
    single: 'shop/category'
});