import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useEffect } from 'react';

function AlertDialog(props) {
    const {
        open,
        handleClose,
        title,
        content
    } = props;

    useEffect(() => {
        console.log(props);
    }, [props.open])

    return (
        <Dialog open={open} onClose={handleClose}>
            {title &&
                <DialogTitle>{title}</DialogTitle>
            }
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autofocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    content: PropTypes.string.isRequired
};

export default AlertDialog;